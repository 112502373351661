<template>
  <v-row v-if="Lb_IsLoaded">
    <v-col cols="12">
      <base-card>
        <v-card-title>{{$t('general.presence_control')}}</v-card-title>
        <v-card-subtitle>
          <v-chip-group
            column
          >
            <v-chip
              color="red"
              text-color="white"
            >
              {{$t('general.absent')}}
            </v-chip>
            <v-chip
              color="green"
              text-color="white"
            >
              {{$t('general.present')}}
            </v-chip>
          </v-chip-group>
        </v-card-subtitle>
        <v-card-text>
          <div>
            <v-sheet
              tile
              height="54"
              color="grey lighten-3"
              class="d-flex"
            >
              <v-btn
                icon
                class="ma-2"
                @click="$refs.calendar.prev()"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-select
                v-model="Ls_ViewType"
                :items="La_ViewTypes"
                dense
                outlined
                hide-details
                class="ma-2"
                :label="$t('general.view_per')"
              />
              <v-select
                v-model="Ls_Mode"
                :items="La_Modes"
                dense
                outlined
                hide-details
                class="ma-2"
                :label="$t('general.event_overlap_mode')"
              />
              <v-select
                v-model="weekday"
                :items="weekdays"
                dense
                outlined
                hide-details
                class="ma-2"
                :label="$t('general.weekdays')"
              />
              <v-spacer />
              <v-btn
                icon
                class="ma-2"
                @click="$refs.calendar.next()"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-sheet>
            <v-sheet height="600">
              <!-- {0} show the Count of more events in the Table Column -->
              <v-calendar
                :event-more-text="'{0} ' +$t('general.more')"
                :locale="$i18n.locale"
                ref="calendar"
                v-model="value"
                :interval-format="intervalFormat"
                :weekdays="weekday"
                :type="Ls_ViewType"
                :events="La_Presence"
                :event-overlap-mode="Ls_Mode"
                :event-overlap-threshold="30"
                :event-color="getEventColor"
                :event-height=40
              />
            </v-sheet>
          </div>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
import { get } from '../../../worker/worker-api';
  export default {
    metaInfo() {
      return{
        // title will be injected into parent titleTemplate
        title: this.$t('general.presence_control'),
      }
    },
    props: {
      // AdressRolleID from Child
      childId: {
        type: Number,
        default: 0
      }
    },    
    data () {
      return {
        Ls_ViewType: 'week',
        La_ViewTypes: [
          {text: this.$t('general.month'),value:'month'},
          {text: this.$t('general.week'),value:'week'},
          {text: this.$t('general.day'),value:'day'},
          {text: this.$t('general.4day'),value:'4day'}
        ],
        Ls_Mode: 'stack',
        La_Modes: [
          {text: this.$t('general.stack'),value:'stack'},
          {text: this.$t('general.column'),value:'column'}
        ],
        weekday: [0, 1, 2, 3, 4, 5, 6],
        weekdays: [
          { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
          { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
          { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
          { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
        ],
        value: '',
        La_Presence: [],
        Lb_IsLoaded: false
      }
    },
    methods: {

      getPresences(Ai_SSP_AddressRoleID,Ai_PlanningPeriodID) {
        store.dispatch('changeThemeLoadingState', true);
        this.Lb_IsLoaded =false;
        return get(`${process.env.VUE_APP_SMT_API_URL}/student/${Ai_SSP_AddressRoleID}/presence/planning-period/${Ai_PlanningPeriodID}`, {})
          .then(response => {
            this.La_Presence = response.presences;
            setTimeout(() => store.dispatch('changeThemeLoadingState', false), 700)
            this.Lb_IsLoaded = true;
          }).catch(error => {
            this.Lb_IsLoaded = true;
            store.dispatch('changeThemeLoadingState', false);
          });
      },

      getEventColor (event) {
        return event.color
      },

      intervalFormat(interval) {//get 24H format
        return interval.time
      },
      
    },

    mounted () {
      if (this.childId != 0){
      this.getPresences(store.getters.getPlanningPeriodID,this.childId);
      }// if
      else{
        this.getPresences(store.getters.getPlanningPeriodID,this.$smt.getAddressRoleID(this.$route.meta.role));
      }
    },
  }
</script>
