<template>
<!-- <div v-if="isParent">
  <v-row>
    <v-col
        cols="12"
        md="6"
        lg="3"
        sm="6"
     >
      <analytic-one-card
        main-icon-name="mdi-certificate"
        main-icon-background-color-class="primary lighten-5"
        main-icon-text-color="primary--text"
        sub-heading-text="Quartal 1"
        heading-text=""
        :bottom-button-text="$t('general.experience_marks')"
        bottom-button-icon="mdi-arrow-down-drop-circle-outline"
        bottom-button-hover-class="hover:bg-primary"
        :bottom-button-link="`/${$i18n.locale}/app/student/experience-marks`"
      />
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="3"
      sm="6"
    >
      <analytic-one-card
        main-icon-name="mdi-certificate"
        main-icon-background-color-class="success lighten-5"
        main-icon-text-color="success--text"
        sub-heading-text="Quartal 1"
        heading-text=""
        :bottom-button-text="$t('general.quarter_marks')"
        bottom-button-icon="mdi-arrow-down-drop-circle-outline"
        bottom-button-hover-class="hover:bg-success"
        :bottom-button-link ="`/${$i18n.locale}/app/student/quarter-marks`"
      />
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="3"
      sm="6"
    >
      <analytic-one-card
        main-icon-name="mdi-certificate"
        main-icon-background-color-class="info lighten-5"
        main-icon-text-color="info--text"
        sub-heading-text="Quartal 1"
        heading-text=""
        :bottom-button-text="$t('general.semester_marks')"
        bottom-button-icon="mdi-arrow-down-drop-circle-outline"
        bottom-button-hover-class="hover:bg-info"
        :bottom-button-link="`/${$i18n.locale}/app/student/semester-marks`"
      />
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="3"
      sm="6"
      @click="gotTofinalMarks(ChildAdressRolle)"
    >
      <analytic-one-card
        main-icon-name="mdi-certificate"
        main-icon-background-color-class="warning lighten-5"
        main-icon-text-color="warning--text"
        sub-heading-text="Quartal 1"
        heading-text=""
        :bottom-button-text="$t('general.final_marks')"
        bottom-button-icon="mdi-arrow-down-drop-circle-outline"
        bottom-button-hover-class="hover:bg-warning"
      />
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <timetable :childId="ChildAdressRolle" />
    </v-col>
  </v-row>
  <v-row>
      <v-col cols="12">
        <presence :childId="ChildAdressRolle" />
      </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <fileManager :childId="ChildAddressID" />
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <Link :childId="ChildAdressRolle" />
    </v-col>
  </v-row>   
</div> -->
<!-- <div v-else>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="3"
      sm="6"
    >
       <analytic-one-card
        main-icon-name="mdi-certificate"
        main-icon-background-color-class="primary lighten-5"
        main-icon-text-color="primary--text"
        sub-heading-text="Quartal 1"
        heading-text=""
        :bottom-button-text="$t('general.experience_marks')"
        bottom-button-icon="mdi-arrow-down-drop-circle-outline"
        bottom-button-hover-class="hover:bg-primary"
        :bottom-button-link="`/${$i18n.locale}/app/student/experience-marks`"
      />
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="3"
      sm="6"
     >
      <analytic-one-card
        main-icon-name="mdi-certificate"
        main-icon-background-color-class="success lighten-5"
        main-icon-text-color="success--text"
        sub-heading-text="Quartal 1"
        heading-text=""
        :bottom-button-text="$t('general.quarter_marks')"
        bottom-button-icon="mdi-arrow-down-drop-circle-outline"
        bottom-button-hover-class="hover:bg-success"
       :bottom-button-link ="`/${$i18n.locale}/app/student/quarter-marks`"
      />
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="3"
      sm="6"
    >
      <analytic-one-card
        main-icon-name="mdi-certificate"
        main-icon-background-color-class="info lighten-5"
        main-icon-text-color="info--text"
        sub-heading-text="Quartal 1"
        heading-text=""
        :bottom-button-text="$t('general.semester_marks')"
        bottom-button-icon="mdi-arrow-down-drop-circle-outline"
        bottom-button-hover-class="hover:bg-info"
        :bottom-button-link="`/${$i18n.locale}/app/student/semester-marks`"
      />
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
        sm="6"
      >
        <analytic-one-card
          main-icon-name="mdi-certificate"
          main-icon-background-color-class="warning lighten-5"
          main-icon-text-color="warning--text"
          sub-heading-text="Quartal 1"
          heading-text=""
          :bottom-button-text="$t('general.final_marks')"
          bottom-button-icon="mdi-arrow-down-drop-circle-outline"
          bottom-button-hover-class="hover:bg-warning"
          :bottom-button-link="`/${$i18n.locale}/app/student/final-marks`"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <timetable />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <presence />
      </v-col>
    </v-row>
  </div> -->
  <div></div>

</template>
<script>
  import analyticOneCard from '@/components/card/AnalyticCardVersionOne'
  import analyticTwoCard from '@/components/card/AnalyticCardVersionTwo'
  import timetable from '@/views/app/apps/Timetable'
  import presence from '@/views/app/apps/Presence'
  import fileManager from '@/views/app/apps/FileManager'
  import Link from '@/views/app/apps/Link'

  import {
    analyticOne,
    analyticTwo,
    analyticThree,
    analyticFour,
    analyticFive,
  } from '@/data/analytic2'
  export default {
    name: 'Analytic',
    metaInfo() {
      return{
      // title will be injected into parent titleTemplate
      title: this.$t('general.Student'),
      }
    },
    components: {
      'analytic-one-card': analyticOneCard,
      'analytic-two-card': analyticTwoCard,
      timetable: timetable,
      presence: presence,
      fileManager: fileManager,
      Link: Link,
    },
    props:['ChildAdressRolle','ChildAddressID'],
    data () {
      return {
        analyticOne,
        analyticTwo,
        analyticThree,
        analyticFour,
        analyticFive,
      } 
    },
    computed: {
      isParent () { 
        return this.$store.getters.getRoleNames.includes('parent')
      }
    },
    methods: {
      gotTofinalMarks(Ai_ChildAdressRolle) {
        this.$router.push({ name: 'final-marks', params: { ChildAdressRolle: Ai_ChildAdressRolle} })
      },
    },
  }
</script>
